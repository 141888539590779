import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import StoryCard from "../components/story-card";

const UnlockStoryCard = () => (
  <div className="add-story-card ae-4 fromCenter">
    <div className="card-content">
      <div className="card-icon">
        <img src="/assets/img/membership-star.png" />
      </div>
      <div className="card-desc light">
        <span>Get access to exclusive content, coaching and events.</span>
      </div>
      <a className="button small rounded ae-5 fromCenter" href="/join_unflux">
        Unlock
      </a>
    </div>
  </div>
);

const LearnPage = ({ data }) => {
  const posts = data.allContentfulUnfluxStory.edges;
  return (
    <Layout>
      <SEO
        title={"Learn"}
        description={"The meeting place for people, products, and great design"}
      />
      <section className="slide whiteSlide kenBurns">
        <div className="content">
          <div className="container">
            <div className="wrap">
              <div className="fix-7-12">
                <h2 className="ae-1 normal margin-bottom-2">
                  Success stories from the creators of your favourite apps.
                </h2>
              </div>
              <div className="ae-2 story-box">
                {posts.map(({ node: post }, index) => (
                  <>
                    <StoryCard
                      story={post}
                      title={post.storyTitle}
                      slug={post.slug}
                      image={post.storyCoverImage.file.url}
                      tag={post.storyTags[0]}
                    />
                    {(posts.length > 2 && index === 2) ||
                    (posts.length <= 2 && index === posts.length - 1) ? (
                      <UnlockStoryCard />
                    ) : null}
                  </>
                ))}
              </div>
              <div className="ae-3">
                <span className="action-director">
                  <div className="action-director-text">Swipe</div>
                  <span>
                    <img alt="Right" src="/assets/img/left-arrow-small.png" />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LearnPage;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulUnfluxStory(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          storyTitle
          slug
          updatedAt(formatString: "MMMM Do, YYYY")
          storyTags
          storyCoverImage {
            file {
              url
            }
          }
          authorName
          authorRole
          authorImage {
            file {
              url
            }
          }
          authorCompany
          authorCompanyImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
