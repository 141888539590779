import React from "react";
import PropTypes from "prop-types";

const StoryCard = ({ story }) => {
  const authorName = story.authorName;
  const authorImage = story.authorImage;

  return (
    <a className="story-card" href={`/story/${story.slug}`}>
      <div className="story-image">
        <img alt="Story" src={story.storyCoverImage.file.url} />
      </div>
      <div className="story-category">{story.storyTags[0]}</div>
      <div className="story-title light">{story.storyTitle}</div>
      <div className="story-author">
        <img alt={authorName} src={authorImage.file.url} />
        <span>{authorName}</span>
      </div>
    </a>
  );
};

StoryCard.propTypes = {
  tag: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.string,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  title: PropTypes.string,
};

export default StoryCard;
